import { ArrowBack } from '@finn/design-system/icons/arrow-back';
import { ArrowForward } from '@finn/design-system/icons/arrow-forward';
import { cn, useIntl } from '@finn/ui-utils';
import Link from 'next/link';
import { usePathname, useSearchParams } from 'next/navigation';
import { useCallback, useMemo } from 'react';

import { useDiscoveryConfig } from '../../core/data/MetadataProvider';
import { handlePageTracking } from '../../tracking';

type Props = {
  page: number;
  direction: 'previous' | 'next';
  disabled?: boolean;
  shallow?: boolean;
};

export const PaginationNavLink = ({
  page,
  direction,
  disabled = false,
  shallow = false,
}: Props) => {
  const i18n = useIntl();
  const pathname = usePathname();
  const searchParams = useSearchParams();
  const { router } = useDiscoveryConfig();

  const linkHref = useMemo(
    () => ({
      pathname,
      query: {
        ...router.query,
        ...Object.fromEntries(searchParams || new Map()),
        page,
      },
    }),
    [router, page, pathname, searchParams]
  );

  const handleClick = useCallback(
    // eslint-disable-next-line @typescript-eslint/no-shadow
    (event, direction) => {
      if (disabled) {
        event.preventDefault();
      } else {
        handlePageTracking({ type: direction });
      }
    },
    [disabled, direction]
  );

  const prevLabel = i18n.formatMessage('plp.pagination.prev');
  const nextLabel = i18n.formatMessage('plp.pagination.next');

  return (
    <Link
      scroll={false}
      shallow={shallow}
      href={disabled ? router.asPath : linkHref}
      className={cn('flex cursor-pointer items-center', {
        'fill-pewter text-pewter': disabled,
        'flex-row-reverse': direction === 'previous',
      })}
      aria-label={direction === 'previous' ? prevLabel : nextLabel}
      onClick={(event) => handleClick(event, direction)}
    >
      <>
        <span className="body-16-semibold mx-2 hidden sm:inline">
          {direction === 'previous' ? prevLabel : nextLabel}
        </span>
        <div>{direction === 'previous' ? <ArrowBack /> : <ArrowForward />}</div>
      </>
    </Link>
  );
};
