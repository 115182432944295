import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from '@finn/design-system/atoms/select';
import { SwapVert } from '@finn/design-system/icons/swap-vert';
import { Features, useIsABVariant } from '@finn/ua-featureflags';
import { useIntl } from '@finn/ui-utils';
import { useCallback } from 'react';

import { FilterKey, SortKey } from '../../../core';
import {
  useFilterValues,
  useMergeFilterValues,
} from '../../../filters-management';
import { trackFilterClicked } from '../../../tracking';
import {
  SortByOption as SortByOptionData,
  useSortByOptions,
} from '../../hooks/useSortByOptions';

export const SortBy = ({ isMobile }: { isMobile: boolean }) => {
  const i18n = useIntl();

  const options = useSortByOptions();

  const filterSortValue =
    useFilterValues((values) => values[FilterKey.SORT] ?? undefined) ||
    SortKey.DEFAULT;
  const mergeFilterValues = useMergeFilterValues();

  const handleClick = useCallback(
    (option?: SortByOptionData) => {
      mergeFilterValues({
        [FilterKey.SORT]: option?.value,
      });
    },
    [mergeFilterValues]
  );

  const defaultLabel = i18n.formatMessage(`plp.sort`);
  const isDefaultSorting = filterSortValue === SortKey.DEFAULT;

  const currentSortOption = options.find(
    (option) => option.value === filterSortValue
  );

  const currentLabel = isDefaultSorting
    ? defaultLabel
    : (currentSortOption?.label ?? defaultLabel);

  const isComparisonExp = useIsABVariant(Features.ExpComparisonV2);

  const triggerExtraProps = isMobile
    ? {
        wrapperClassName: isComparisonExp ? 'w-1/4' : 'w-1/2',
        className: 'w-full flex-row-reverse justify-center border-black',
        icon: (
          <SwapVert className="max-h-6 max-w-6 fill-black transition-none" />
        ),
      }
    : { size: 'md' as const };
  const valueProps = isMobile
    ? {
        className: isComparisonExp
          ? 'hidden'
          : '[&_span]:body-16-semibold w-auto',
        placeholder: isComparisonExp ? '' : i18n.formatMessage('plp.sort'),
        forcePlaceholder: true,
      }
    : {
        className: '[&_span]:body-12-light',
        placeholder: currentLabel,
        forcePlaceholder: currentLabel === defaultLabel,
      };

  return (
    <Select
      value={currentSortOption?.value}
      onOpenChange={(nextValue) => {
        if (nextValue) {
          trackFilterClicked({ type: 'sorting' });
        }
      }}
      onValueChange={(value) => {
        handleClick(options.find((option) => option.value === value));
      }}
    >
      <SelectTrigger aria-label="Sortieren nach" {...triggerExtraProps}>
        <SelectValue {...valueProps} />
      </SelectTrigger>
      <SelectContent className="max-h-full">
        {options.map((option) => (
          <SelectItem key={option.value} value={option.value as string}>
            <span>{option.label}</span>
          </SelectItem>
        ))}
      </SelectContent>
    </Select>
  );
};
